export enum RESERVATION_TYPE {
  RESERVE = 1,
  TASK = 2,
  PUBLIC_HOLIDAY_AND_OTHERS = 3,
}

export enum GENDER_TYPE {
  MALE = 0,
  FEMALE = 1,
}

export enum RESERVATION_STATUS {
  NOT_CONFIRMED = 1,
  CONFIRMED = 2,
  RECEPTION = 3,
  TOTAL = 4,
  ACCOUNTED = 5,
  CANCEL = 6,
}

export enum CONSULTATION_CLASSIFICATION {
  FIRST_VISIT = 1,
  CONTINUOUS_TREATMENT = 2,
  RE_EXAMINATION = 3,
  CONFIRMATION_REQUIRED = 4,
}

export enum SYMPTOMS {
  DAILY_PAIN = 1,
  TRAFFIC_ACCIDENT = 2,
}

export enum AREA {
  SHIGA = 25,
  KYOTO = 26,
  OSAKA = 27,
}

export enum RESERVATION_ROUTE {
  LINE = 2,
}

export enum PRACTITIONER_AVAILABILITY {
  NOT_AVAILABLE = 0,
  ONLY_ONE_AVAILABLE = 1,
  MORE_THAN_TWO_AVAILABLE = 2,
}
