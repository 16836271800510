import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51c2766a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-screen" }
const _hoisted_2 = { class: "page-title" }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = { class: "required" }
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = {
  key: 0,
  class: "text-danger fw-bold"
}
const _hoisted_7 = { class: "mb-3" }
const _hoisted_8 = { class: "required" }
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = {
  key: 0,
  class: "text-danger fw-bold"
}
const _hoisted_11 = {
  key: 1,
  class: "text-danger fw-bold"
}
const _hoisted_12 = { class: "next-prev-buttons d-flex justify-content-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("EnterReservationInformation.Title")), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("EnterReservationInformation.Name")), 1),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        placeholder: _ctx.$t('EnterReservationInformation.ExampleName'),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.reservationInfoForm.customerName) = $event)),
        onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.convertToFullWidthName && _ctx.convertToFullWidthName(...args))),
        onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleTrim && _ctx.handleTrim(...args))),
        maxlength: "255",
        pattern: "[\\u30A1-\\u30FC]*",
        required: "true"
      }, null, 40, _hoisted_5), [
        [_vModelText, _ctx.reservationInfoForm.customerName]
      ]),
      (_ctx.isCustomerNameRequired)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t("Errors.E112")), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("EnterReservationInformation.MobileNumber")), 1),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        placeholder: _ctx.$t('EnterReservationInformation.PleaseFillIn'),
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.reservationInfoForm.cellNo) = $event)),
        required: "true",
        minlength: "10",
        maxlength: "11",
        onKeypress: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleNumber($event))),
        onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleNumberRegex($event)))
      }, null, 40, _hoisted_9), [
        [_vModelText, _ctx.reservationInfoForm.cellNo]
      ]),
      (_ctx.isCellNoRequired)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t("Errors.E112")), 1))
        : _createCommentVNode("", true),
      (_ctx.isCellIsLess || _ctx.isInvalidCellNo)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t("Errors.E063")), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t("EnterReservationInformation.SymptomsOfTrouble")), 1),
    _withDirectives(_createElementVNode("textarea", {
      class: "mb-0",
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.reservationInfoForm.symptomAndTouble) = $event)),
      onKeyup: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setSymptomAndTrouble($event)))
    }, "\n    ", 544), [
      [_vModelText, _ctx.reservationInfoForm.symptomAndTouble]
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("button", {
        class: "btn prev-button d-block",
        href: "#",
        onClick: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)), ["prevent"]))
      }, _toDisplayString(_ctx.$t("EnterReservationInformation.Return")), 1),
      _createElementVNode("button", {
        class: "btn btn-primary d-block",
        href: "#",
        onClick: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.goNext && _ctx.goNext(...args)), ["prevent"]))
      }, _toDisplayString(_ctx.$t("EnterReservationInformation.ToTheNext")), 1)
    ])
  ]))
}