export default {
  "Access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクセス"])},
  "Voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン"])},
  "BookingEnquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約照会"])},
  "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お知らせ"])},
  "ContactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問合せ/電話予約"])},
  "OnlineBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネット予約"])},
  "LoginPage": {
    "LoginScreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン画面"])},
    "SavePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを保存"])},
    "ForgetPassword?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをお忘れですか？"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])}
  },
  "Sidebar": {
    "ReservationCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約カレンダー"])},
    "ReservationListToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約本日一覧"])},
    "ReservationList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約一覧"])},
    "ReservationHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約履歴"])},
    "CustomerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客一覧"])},
    "PriceMedical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["料金カルテ"])},
    "TaskSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクスケジュール"])}
  },
  "Calendar": {
    "Details": {
      "SelectedDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8月9日（火）"])},
      "SelectedTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18:00～19:00"])},
      "ReservationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約種別"])},
      "Classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["種別"])},
      "Symptoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["症状"])},
      "Treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["施術"])},
      "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状態"])},
      "ReservationRoute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約経路"])},
      "CancleReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約キャンセル"])},
      "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])},
      "Reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受付"])},
      "MedicalAdulate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カルテ記入"])},
      "SecondBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次回"])},
      "Accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会計"])},
      "PatientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["患者名"])},
      "TicketRemaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残回数券："])},
      "MemberTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残会員券："])},
      "Receivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残売掛金："])},
      "AdministrativeNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理メモ"])},
      "Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["備考欄"])}
    },
    "Buttons": {
      "ReservationMemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約メモ"])}
    }
  },
  "Form": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約登録"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間"])},
    "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店舗"])},
    "practitioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["施術者"])},
    "res-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約種別"])},
    "Placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（記入してください）"])},
    "Reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約"])},
    "Task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスク"])},
    "PublicHolidaysAndOthers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公休・その他"])},
    "ReservationMemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約メモ"])},
    "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状態"])},
    "Pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未確定"])},
    "Finalized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確定"])},
    "Accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受付"])},
    "Accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会計"])},
    "CheckedOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会計済み"])},
    "Cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
    "CustomerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客名"])},
    "CustomerSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客検索"])},
    "ReservationPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約者"])},
    "TelephoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
    "FirstVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初診"])},
    "OngoingTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["治療継続中"])},
    "ReExamination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再診"])},
    "ToConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要確認"])},
    "ConsultationClassification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受診区分"])},
    "TreatmentMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["施術メニュー"])},
    "DiagnosisDistinction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受診区分"])},
    "Block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブロック"])},
    "Symptoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["症状"])},
    "EverydayPain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日常痛み"])},
    "Unfinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未完了"])},
    "Completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
    "TaskType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスク種別"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細"])},
    "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブロックする"])},
    "Sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確定"])},
    "PublicHolidayType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公休種別"])},
    "NotSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未確定"])},
    "Reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受付"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会計"])},
    "Accounted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会計済み"])},
    "TreatmentNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["治療注記"])},
    "Acupuncture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鍼治療"])},
    "Insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保険"])},
    "TrafficAccident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交通事故"])},
    "ManagementMemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理メモ"])},
    "Button": {
      "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
      "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録"])}
    }
  },
  "SearchModal": {
    "CustomerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客番号"])},
    "CustomerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客名"])},
    "TelephoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
    "Placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（記入してください）"])},
    "CustomerSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客検索"])},
    "Store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店舗"])},
    "YourName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["おなまえ"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お名前"])},
    "CellphoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯番号"])},
    "AdministrativeNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理メモ"])},
    "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別"])},
    "LastVisitDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最終来院日"])},
    "SouthernDistrictMainHospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南区　本院"])},
    "YamadaIchirou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["やまだいちろう"])},
    "TaroYamada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["やまだたろう"])},
    "YamadaHana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["やまだはな"])},
    "IchiroYamada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["山田一郎"])},
    "YamadaTaro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["山田太郎"])},
    "YamadaFlower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["山田花"])},
    "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男"])},
    "Female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女"])}
  },
  "ReservationDetails": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約詳細"])},
    "FirstVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初診"])},
    "Yamada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["やまだ"])},
    "YamadaTaro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["山田太郎"])},
    "Mr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["様"])},
    "LastVisitDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最終来院日"])},
    "HVNotPossible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HV不可"])},
    "NoAcupuncture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鍼不可"])},
    "MetalHas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金属有"])},
    "DisabledMemberCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残会員券"])},
    "Sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枚"])},
    "RemnantCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残回数券"])},
    "AccountsReceivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残売掛金"])},
    "Circle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["円"])},
    "LastTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前回"])},
    "Reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受付"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会計"])},
    "NextTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次回"])},
    "CancelReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約キャンセル"])},
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付"])},
    "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
    "Moon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
    "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
    "Water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水"])},
    "Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間"])},
    "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始"])},
    "End": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了"])},
    "Store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店舗"])},
    "Demachiyanagi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出町柳"])},
    "Practitioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["施術者"])},
    "OnoSister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小野妹子"])},
    "ReservationStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約状態"])},
    "Sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確定"])},
    "CancellationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル種別"])},
    "Member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員"])},
    "CustomerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様番号"])},
    "ReservationPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約者"])},
    "TelephoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
    "Tabs": {
      "Reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約"])},
      "PreliminaryExamination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予診"])},
      "Inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問診"])},
      "Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方針"])},
      "Treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["施術"])},
      "Personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パーソナル"])}
    },
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
    "ConsultationClassification": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受診区分"])},
      "FirstVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初診"])},
      "OngoingTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["治療継続中"])},
      "ReExamination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再診"])},
      "ToConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要確認"])}
    },
    "TreatmentMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["施術メニュー"])},
    "Minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分"])},
    "Symptom": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["症状"])},
      "EverydayPain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日常痛み"])},
      "TrafficAccident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交通事故"])}
    },
    "ManagementMemo": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理メモ"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ管理メモ"])}
    },
    "CustomerInformationRemarks": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客記載事項 （備考）"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客記載事項顧客記載事項顧客記載事項顧客記載事項顧客記載事項顧客記載事項顧客記載事項顧客記載事項顧客記載事項顧客記載事項顧客記載事項顧客記載事項顧客記載事項顧客記載事項顧客記載事項顧客記載事項顧客記載事項顧客記載事項顧客記載事項顧客記載事項顧客記載事項"])}
    },
    "ReservationRoute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約経路"])},
    "AkiraWatanabe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["渡辺暁斗"])},
    "NoboruYamada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["山田 登"])},
    "AfterTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["施術後"])},
    "TanakaNebula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["田中星雲"])},
    "Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-3日前から左腕が上がらない"])},
    "CreateNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規作成"])}
  },
  "Questions": {
    "Title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["どんな症状でお悩みですか（複数選択）"])},
    "Title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["どこに症状がありますか（複数選択）"])},
    "Title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いつ頃から症状が出ていますか？"])},
    "Title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペースメーカーが入ってますか？"])},
    "Title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["体内に金属が入っていますか？"])},
    "Title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["妊婦または妊娠の可能性はありますか？"])},
    "Title7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今までに病院でヘルニア・脊柱管狭窄・坐骨神経痛等の診断を受けられたことはありますか？"])},
    "Title8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今までに手術を受けられたことは有りますか？"])},
    "Title9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在、ご家族で下記のことでお困りの方はいらっしゃいますか？"])},
    "Title10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["困られている方はどちらの方になるでしょうか？"])},
    "pain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["痛い"])},
    "Hardened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["硬くなっている"])},
    "Numb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["しびれる"])},
    "Dull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["だるい"])},
    "DoesntWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動かない"])},
    "Others": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他"])},
      "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テキストテキスト"])}
    },
    "Head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["頭"])},
    "Neck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首"])},
    "Shoulder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["肩"])},
    "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["背中"])},
    "Waist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["腰"])},
    "Buttocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お尻"])},
    "Coxa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["股関節"])},
    "Arm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["腕"])},
    "Elbow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ひじ"])},
    "Wrist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手首"])},
    "Hand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手"])},
    "Finger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指"])},
    "Thigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ふともも"])},
    "Knee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ひざ"])},
    "Shank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すね"])},
    "Calf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ふくらはぎ"])},
    "Foot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["足"])},
    "Ankle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["足首"])},
    "Toe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["足指"])},
    "MoreThanTen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10年以上前"])},
    "MoreThanOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1年前以上"])},
    "SixToThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["半年－3ヶ月前"])},
    "OneToThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1－3ヶ月前"])},
    "WithinOneMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1ヶ月以内"])},
    "WithinTwoThreeDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2－3日以内"])},
    "Yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昨日"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はい"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いいえ"])},
    "NameOfDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["診断名"])},
    "KindOfSurgery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["なんの手術ですか？"])},
    "ChronicStiffShoulders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["慢性的な肩こり・腰痛"])},
    "Fatigue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スポーツにより疲労や怪我"])},
    "InjuriesAccident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交通事故による怪我"])},
    "Father": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["父"])},
    "Mother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["母"])},
    "OlderBrother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兄"])},
    "YoungerBrother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["弟"])},
    "OlderSister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姉"])},
    "YoungerSister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["妹"])},
    "Husband": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["夫"])},
    "Wife": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["妻"])},
    "Child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子"])}
  },
  "ReservationCancelModal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約キャンセル"])},
    "contacted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡あり"])},
    "noContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡なし"])},
    "afterReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受付後"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["院都合"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録"])}
  },
  "DeletionModal": {
    "deleteScreeningSlip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予診票を削除しますか？"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除しますか？"])},
    "publishedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021年09月23日"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予診票（表）"])},
    "advance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予診票（裏）"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成者:"])},
    "updaters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新者:"])},
    "deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])}
  },
  "Policy": {
    "MainComplaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主訴"])},
    "FillIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（記入してください）"])},
    "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テキストテキスト"])},
    "PathologicalBehavior": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原因動作"])},
      "ExcessiveMovement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動かしすぎ"])},
      "TooMuchMovement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動かなさすぎ"])},
      "Trauma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外傷"])}
    },
    "Finding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所見"])},
    "See": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所見"])},
    "Cause": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原因筋"])},
      "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原因筋追加"])},
      "Place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部位："])},
      "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（選択）"])},
      "Muscle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筋肉："])},
      "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原因筋削除"])}
    },
    "Registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録"])},
    "Deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])}
  },
  "TrafficAccident": {
    "CustomerRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客登録"])},
    "SelectPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（ファイルを選択・撮影してください）"])},
    "SelectQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予診票（表）を選択"])},
    "SelectPreExamSlip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予診票（裏）を選択"])},
    "Registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録"])}
  },
  "Inquiry": {
    "SelectPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（ファイルを選択・撮影してください）"])},
    "SelectQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問診票を選択"])},
    "Registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録"])}
  },
  "Treatment": {
    "MainComplaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主訴"])},
    "Progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前回からの経過"])},
    "Placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（記入してください）"])},
    "Stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステージ"])},
    "TodaySymptoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今日の症状"])},
    "AdditionalSymptoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["症状追加"])},
    "Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部位："])},
    "Sense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["感覚："])},
    "MainMenu": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メインメニュー"])},
      "HeadAndShoulders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首肩"])},
      "ShoulderJoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["肩関節"])},
      "Forearm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前腕部"])},
      "WaistBag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["腰バッグ"])},
      "WaistFront": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["腰フロント"])},
      "NumbnessInLowerLimbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下肢しびれ"])},
      "NumbnessTnTheSole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["足底しびれ"])},
      "LowerLeg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下腿"])},
      "Tightly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ぎっくり"])},
      "WShoulderJoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W肩関節"])},
      "WFrontWrist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W前腕部"])},
      "WLowerLeg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W下腿"])},
      "LumbarDecubitus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["腰側臥位"])}
    },
    "EffectUpMenu": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["効果アップ"])},
      "Acupuncture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鍼"])}
    },
    "EffectKeepMenu": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["効果キープ"])},
      "Yen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["円（－枚）"])},
      "Tape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テープ（－枚）"])},
      "Zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゼロ（－枚）"])},
      "CastFixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ギブス固定"])},
      "BandageChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包帯交換"])}
    },
    "Action": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクション"])},
      "WarmTasting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["温感試飲"])},
      "WarmworkBooklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["温活冊子"])},
      "StretchBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストレッチ本"])},
      "Equiten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エキテン"])},
      "GoogleMapReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グーグルマップレビュー"])},
      "Whiteboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホワイトボード"])}
    },
    "InsuranceTreatment": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保険治療"])},
      "TrafficAccident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交通事故"])}
    },
    "Creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成者"])},
    "Toyama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外山"])},
    "Updater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新者"])},
    "Mao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["茂"])}
  },
  "Ticket": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予診票"])},
    "Finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了"])},
    "Return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
    "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次へ"])},
    "Step1": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カルテ作成のためのお客様情報をご入力ください"])},
      "Phonetic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フリガナ"])},
      "Required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必須"])},
      "Placeholder1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例：やまだ"])},
      "Placeholder2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例：やまだ"])},
      "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お名前"])},
      "KanaLastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カナ姓"])},
      "KanaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カナ名"])},
      "KanjiLastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["漢字姓"])},
      "KanjiName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["漢字名"])},
      "Placeholder3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例：山田"])},
      "Placeholder4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例：太郎"])},
      "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別"])},
      "Man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男"])},
      "Woman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女"])},
      "DoB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生年月日"])},
      "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
      "Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
      "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
      "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（選択）"])},
      "Zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号"])},
      "AddressSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所検索"])},
      "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市区町村・番地"])},
      "Building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建物名など"])},
      "PhoneNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
      "Mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯"])},
      "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自宅"])},
      "Occupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご職業"])}
    },
    "Step2": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご症状についてお教えください。"])}
    },
    "Step3": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートにご回答ください"])},
      "Questions": {
        "Q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当院に来院する決め手となったものを一つお選びください"])},
        "Options": {
          "Acquaintance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["知人より"])},
          "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お名前"])},
          "Family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家族から"])},
          "Coworkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社の同僚"])},
          "Flyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チラシ"])},
          "HomePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当院のホームページ"])},
          "SearchTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索キーワード"])},
          "Placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テキストテキストテキスト"])},
          "GoogleMaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleマップ"])},
          "Equiten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エキテン"])},
          "Facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
          "HotPepper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホットペッパー"])},
          "OtherOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他オンライン"])},
          "Appearance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外観　通りがかり"])},
          "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他"])}
        },
        "Q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハガキなどでご連絡を差し上げてもよろしいでしょうか？"])},
        "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はい"])},
        "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いいえ"])},
        "Q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス向上の為にカウンセリング内容を記録しています （どちらかにチェックをお願いします）"])},
        "Allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["撮影を許可する"])},
        "DontAllow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["撮影を許可しない"])}
      }
    }
  },
  "PersonalInformation": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人情報について"])},
    "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人情報についてテキストテキスト"])},
    "Agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上記の個人情報取り扱い事項について同意します。（必須）"])},
    "Instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人情報取扱について内容をご確認・同意の上 以下、「次へ」ボタンを押して完了です。 係の者にお渡しください。"])},
    "Return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
    "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次へ"])}
  },
  "CustomerInformation": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客情報"])},
    "FuriganaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ふりがな"])},
    "FuriganaFirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["やまだ"])},
    "FuriganaLastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["たろう"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お名前"])},
    "FirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["山田"])},
    "LastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["太郎"])},
    "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別"])},
    "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男"])},
    "Birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生年月日"])},
    "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
    "Moon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
    "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
    "PostalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号"])},
    "CityAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市区町村・番地"])},
    "CityAddressDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["京都府京都市南区西九条西柳ノ内町xx−x"])},
    "NameOfBuildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建物名など"])},
    "MobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話番号"])},
    "HomePhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自宅電話番号"])},
    "Profession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご職業"])},
    "Employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社員"])}
  },
  "CustomerDetails": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客詳細"])},
    "Details": {
      "ReservationID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約ID"])},
      "CustomerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様番号"])},
      "RegisteredStores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録店舗"])},
      "LastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓"])},
      "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名"])},
      "KanaLastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カナ姓"])},
      "KanaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カナ名"])},
      "CustomerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客名"])},
      "MobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話"])},
      "HomeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自宅電話番号"])},
      "EmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス"])},
      "DoB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生年月日"])},
      "ZipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号"])},
      "Prefecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["都道府県"])},
      "CityAndStreet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市区町村・番地"])},
      "Building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建物名など"])},
      "PersonalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パーソナル情報"])},
      "AdminRemarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理用備考"])},
      "NoOfVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通院回数"])},
      "FirstVisitDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初診担当者"])},
      "lastVisitDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最終来院日"])},
      "ReexaminationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再診日"])},
      "InchargeFirstVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初診担当者"])},
      "InchargeReexamination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再診担当者"])},
      "FirstDaySelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自賠初診日"])},
      "FirstDayCompensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f労災初診日"])},
      "NotAtAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鍼不可"])},
      "HVNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HV不可"])},
      "Metals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金属有"])},
      "MetalParts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金属部位"])},
      "InsuranceAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保険治療有"])},
      "Compensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交通事故・労災治療有"])},
      "AcupunctureAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鍼治療有"])},
      "MemberRemainingCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員残回数券"])},
      "RemainingCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残回数券"])},
      "RemainingAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残売掛金"])},
      "MonthlyMembershipDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月額会員入会日"])},
      "MonthlyTemporaryRecess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月額会員一時休会日"])},
      "MonthlyWithdrawlRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月額会員退会日"])},
      "InflowPath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["流入経路"])},
      "SearcTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索キーワード"])},
      "OtherInflowPath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他流入経路"])},
      "Notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お知らせメール許可"])},
      "Shooting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["撮影許可"])},
      "EReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー_エキテン有"])},
      "GReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー_Gレビュー有"])},
      "WBReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー_WB有"])}
    },
    "CumulativePurchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累計購入数"])},
    "CumulativeUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累計使用数"])},
    "Remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残回数"])},
    "Append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調整行追加"])},
    "RemainingAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残売掛金"])},
    "Treatment": {
      "Complaints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主訴 :"])},
      "Progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前回からの経過 :"])},
      "Stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステージ :"])},
      "TodaySymptom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今日の症状 :"])},
      "Place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部位："])},
      "Sensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["感覚："])},
      "Main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メイン :"])},
      "Muscle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筋肉：:"])},
      "EffectUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["効果アップ :"])},
      "EffectKeep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["効果キープ :"])},
      "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクション :"])},
      "InsuranceTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保険治療 :"])}
    },
    "WarmPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["温感プラス"])},
    "Vitamins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トリガーポイントビタミン"])},
    "Modal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調整行編集"])},
      "Purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入数"])},
      "Usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用数"])},
      "Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["備考"])}
    },
    "Table": {
      "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付"])},
      "Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払方法"])},
      "ProductMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品メニュー"])},
      "Purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入数"])},
      "Usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用数"])},
      "Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["備考"])},
      "Operate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
      "ExpiryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効期限"])},
      "IncreaseAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["増加金額"])},
      "DecreaseAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["減少金額"])},
      "DeliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受渡日"])}
    }
  },
  "AboutSymptoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご症状について"])},
  "CheckInformationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様が入力した情報を確認して下さい。"])},
  "ThankYouTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お答えいただきありがとうございます！"])},
  "TodayList": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本日一覧"])},
    "ReservationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約日"])},
    "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始"])},
    "End": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了"])},
    "Store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店舗"])},
    "SouthernDistrictMainHospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南区本院"])},
    "Practitioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["施術者"])},
    "PleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（選択してください）"])},
    "CustomerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客名"])},
    "PleaseEnter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(入力してください)"])},
    "CustomerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客番号"])},
    "PleaseFillIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（記入してください）"])},
    "ConsultationClassification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受診区分"])},
    "FirstVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初診"])},
    "OngoingTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["治療継続中"])},
    "ReExamination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再診"])},
    "ToConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要確認"])},
    "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全て"])},
    "Symptom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["症状"])},
    "EverydayPain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日常痛み"])},
    "TrafficAccident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交通事故"])},
    "TreatmentMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["施術 メニュー"])},
    "ReservationStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約状態"])},
    "NotSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未確定"])},
    "Sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確定"])},
    "Reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受付"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会計"])},
    "Accounted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会計済"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
    "ReservationRoute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約経路"])},
    "ReservationRegistrationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約登録日"])},
    "MedicalRecordBlank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カルテ未記入"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])}
  },
  "ReservationStoreSelection": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約店舗選択"])},
    "VisitHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来院歴"])},
    "First": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はじめて"])},
    "UnderContinuousTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["継続治療中"])},
    "AfterAWhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["しばらくぶり"])},
    "ReserveByPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在通院中です。再診をご希望の場合はお電話にてご予約をお願いします。"])},
    "Symptom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["症状"])},
    "EverydayPain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日常的な痛み"])},
    "TrafficAccident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交通事故"])},
    "Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エリア"])},
    "Kyoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["京都"])},
    "Osaka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大阪"])},
    "Shiga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["滋賀"])},
    "Store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店舗"])},
    "PleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（選択してください）"])},
    "ToTheNext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次へ"])}
  },
  "IdLinkage": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID連携"])},
    "CustomerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様番号"])},
    "PleaseFillIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（記入してください）"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お名前"])},
    "ExampleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例：山田 太郎"])},
    "Birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生年月日"])},
    "ExampleDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例：1999年01月01日の場合→　19990101"])},
    "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別"])},
    "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男"])},
    "Female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女"])},
    "MobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号（携帯 or 自宅）"])},
    "MobilePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例）090-1234-5678　→09012345678"])},
    "Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※お客様番号が不明な場合は、お電話にて予約をお願いします。"])},
    "ToTheNext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次へ"])}
  },
  "EnterReservationInformation": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約情報入力"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お名前"])},
    "ExampleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例：山田 太郎"])},
    "MobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話番号"])},
    "PleaseFillIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（記入してください）"])},
    "MainHospitalName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お名前"])},
    "PhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
    "SymptomsOfTrouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お困りの症状"])},
    "InflowRoute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["流入経路"])},
    "Return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
    "ToTheNext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次へ"])},
    "CustomerNumberRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様番号が必要です"])},
    "CustomerCellNumberRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号が必要です"])},
    "CustomerCellNumberInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無効な番号"])}
  },
  "ReservationAcceptanceCompleted": {
    "ConfirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約内容確認"])},
    "AcceptanceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約完了"])},
    "ReservationConfirmationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約確認番号：12345678"])},
    "ReservationDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご予約内容"])},
    "ReservationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約日時"])},
    "NotConfirmedYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだ予約は完了していません"])},
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022年01月02日 10:00"])},
    "ReservationStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約店舗"])},
    "Minami": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南区本院"])},
    "TroublesomeSymptoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お困りの症状"])},
    "SymptomsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テキスト"])},
    "RegisterAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予定をカレンダーに登録する"])},
    "Notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["諸注意"])},
    "NoteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テキストテキスト"])},
    "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閉じる"])},
    "NoReservationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約がありません。"])}
  },
  "DuringMaintenance": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンテナス中"])},
    "Resumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再開は、"])},
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022年01月02日 午前4時"])},
    "Schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["の予定です"])}
  },
  "TodayListTable": {
    "Head": {
      "ReservationDateAndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約日時"])},
      "CustomerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客名"])},
      "TelephoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
      "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状態"])},
      "TreatmentMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["施術メニュー"])},
      "Practitioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["施術者"])},
      "ManagementMemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理メモ"])},
      "ReservationRegistrationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約登録日"])}
    },
    "Names": {
      "ErnestWilson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーネスト ウィルソン"])},
      "YamadaTaro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["山田 太郎"])},
      "Arai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アライ"])},
      "Yasuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヤスダ"])},
      "YamadaHana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["山田 花"])},
      "KotaroYamada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["山田 孝太郎"])}
    },
    "Status": {
      "Accounted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会計済"])},
      "Reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受付"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
      "Sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確定"])}
    },
    "Points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分"])},
    "TrafficAccident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交通事故・労災（30分）"])},
    "Acupuncture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鍼治療（30分）"])},
    "Toyama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外山"])},
    "AbeWomanHope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿部女性希望"])},
    "TakeshitaHospitalityCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["竹下接遇チェッ‥"])}
  },
  "MobileCalendar": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約日時選択"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご希望の来店日時を選択してください"])},
    "Previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前の一週間"])},
    "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の一週間"])},
    "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["：受付中"])},
    "FewLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["：残りわずか"])},
    "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["：受付終了"])},
    "Return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])}
  },
  "Tables": {
    "BeApplicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["適用"])},
    "Practitioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["施術者"])}
  },
  "TaskListTable": {
    "Head": {
      "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年月日"])},
      "Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間"])},
      "Store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店舗"])},
      "Practitioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["施術者"])},
      "Kinds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["種別"])},
      "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状態"])},
      "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細"])}
    },
    "SouthernDistrictMainHospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南区本院"])},
    "Toyama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外山"])},
    "Takeda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["武田"])},
    "RookieTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ルーキー研修"])},
    "AcupunctureTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鍼研修"])},
    "MonthlyReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月次報告"])},
    "ESConference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES会議"])},
    "Unfinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未完了"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])}
  },
  "CustomerListTable": {
    "Head": {
      "CustomerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様番号"])},
      "Store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店舗"])},
      "CustomerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客名"])},
      "TelephoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
      "LastVisitDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最終来院日"])},
      "RemnantCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会残数券"])},
      "Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー"])},
      "ManagementPreparation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理用備考"])}
    },
    "SouthernDistrictMainHospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南区本院"])},
    "ErnestWilson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーネスト ウィルソン"])},
    "YamadaTaro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["山田 太郎"])},
    "YamadaHana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["山田 花"])},
    "KotaroYamada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["山田 孝太郎"])},
    "Workman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エ"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W"])},
    "TakeshitaHospitalityCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["竹下接遇チェッ‥"])},
    "TransferredFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["久我の杜より転院"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細"])},
    "MedicalRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カルテ"])}
  },
  "CustomerListSearch": {
    "Store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店舗"])},
    "PleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（選択してください）"])},
    "CustomerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客名"])},
    "PleaseEnter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(入力してください)"])},
    "TelephoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
    "PleaseFillIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（記入してください）"])},
    "CustomerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様番号"])},
    "BirthMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["誕生月"])},
    "Choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（選択）"])},
    "Moon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
    "Age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年齢"])},
    "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始"])},
    "End": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了"])},
    "Years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歳"])},
    "LastVisitDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最終来院日"])},
    "ExampleDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例：2021-08-09"])},
    "BaoyeTreatmentHas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保険治療有"])},
    "TreatmentAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交通事故・労災治療有"])},
    "NumberOfHospitalVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通院回数："])},
    "Times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回"])},
    "Member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員："])},
    "TenTimesOrMoreLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残10回以上"])},
    "ZeroTimesLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残0回"])},
    "RemainingMinus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残マイナス"])},
    "ProductSalesMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物販会員："])},
    "UnsettledThisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今月未決済"])},
    "NotDeliveredThisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今月未渡し"])},
    "Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー："])},
    "Equiten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エキテン"])},
    "GReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gレビュー"])},
    "Others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他:"])},
    "ARemainingCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残回数券有り"])},
    "AccountsReceivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売掛金有り"])},
    "NonMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非会員"])},
    "Anonymous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名無し"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])}
  },
  "TaskListSearch": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスク一覧"])},
    "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状態"])},
    "Unfinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未完了"])},
    "Completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])}
  },
  "Return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
  "MakeAReservationWithTheAboveContents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上記内容で予約する"])},
  "Errors": {
    "E050": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "は登録できる桁数を超えています"])},
    "E051": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "は必須です"])},
    "E052": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "は登録できない文字が入力されています"])},
    "E053": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "が正しくありません"])},
    "E054": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["1件以上の", _interpolate(_named("field")), "を入力してください"])},
    "E055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メイン、効果アップ、効果キープのいずれにも入力がありません"])},
    "E056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メインが未選択です"])},
    "E070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全ての項目を入力してください"])},
    "E071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他の方が先に更新したようです。前の画面に戻って再表示してください"])},
    "E072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他の予約が先に登録されたようです。再度登録を行ってください"])},
    "E100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受診区分を初診・継続治療・再診のいずれかに修正してください"])},
    "E101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客情報が登録済みかもしれません。ご確認ください"])},
    "E102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客情報を登録してください"])},
    "E103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様番号の顧客名と予約者名が異なります"])},
    "E104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様番号の電話番号と予約電話番号が異なります"])},
    "E105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定の日付、時間、施術者には既に予定が登録されています"])},
    "E106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約メモの場合は施術者の指定はできません"])},
    "E107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行を選択してください"])},
    "E108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約状態を選択してください"])},
    "E109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択された行はキャンセルにできません"])},
    "E110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択された行は受付後キャンセルにはできません"])},
    "E111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択された行は更新されています"])},
    "E112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索条件項目が未入力です"])},
    "E113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロードでエラーが発生しました"])},
    "E114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["施術が登録されているため削除できません"])},
    "E115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客が登録されていません"])},
    "E116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロードする交通事故予診票を選択してください"])},
    "E117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロードする問診票を選択してください"])},
    "E060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桁数超過"])},
    "E061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必須"])},
    "E062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字種違い"])},
    "E063": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["形式違い"])},
    "C100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次回の予約が決まってないですがよろしいでしょうか？"])},
    "C101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次月の物販をオススメする時期です"])},
    "C102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受診区分を自動で設定しました"])},
    "C103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員回数券が残り1枚です。"])},
    "L100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複数件の予約は、お電話にて承ります"])},
    "L101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お電話にてご予約を承ります"])},
    "L102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様は再診となります"])},
    "L103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様は継続治療となります"])},
    "L104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在通院中です。再診をご希望の場合はお電話にてご予約をお願いします"])},
    "L105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様の情報を照合できませんでした。お電話にてご予約を承ります"])},
    "L106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご指定の日時の予約が確保できませんでした。他の日時をご選択ください"])}
  }
}