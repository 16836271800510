
import { ReservationInfo } from "@/core/interfaces";
import { defineComponent, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { store } from "../core/store";
import { convertToFullWidthKana, testNumber } from "@/core/utils/index";

export default defineComponent({
  name: "ReservationInformation",
  components: {},
  setup() {
    const router = useRouter();

    const { customer, reservationInfo } = store.getters;
    const reservationInfoForm = reactive<ReservationInfo>({
      ...reservationInfo,
      customerName: reservationInfo.customerName || localStorage.getItem('Fullname') ? localStorage.getItem('Fullname')!.replace(/['"]+/g, '') : '',
      cellNo: reservationInfo.cellNo ||  localStorage.getItem('CellNo')  ? localStorage.getItem('CellNo')!.replace(/['"]+/g, '') : '',
      symptomAndTouble: reservationInfo.symptomAndTouble ||localStorage.getItem('Troubles')  ? localStorage.getItem('Troubles')!.replace(/['"]+/g, '') : ''
    });
    const isCustomerNameRequired = ref<boolean>(false);
    const isCellNoRequired = ref<boolean>(false);
    const isCellIsLess = ref<boolean>(false);
    const isInvalidCellNo = ref<boolean>(false);

    const goBack = () => {
      router.back();
    };

    const goNext = () => {
      isCustomerNameRequired.value = false;
      isCellNoRequired.value = false;
      isCellIsLess.value = false;
      isInvalidCellNo.value = false;

      if (
        !reservationInfoForm.customerName ||
        !reservationInfoForm.cellNo ||
        reservationInfoForm.cellNo.length < 10
      ) {
        if (!reservationInfoForm.customerName) {
          isCustomerNameRequired.value = true;
        } else {
          isCustomerNameRequired.value = false;
        }
        if (!reservationInfoForm.cellNo) {
          isCellNoRequired.value = true;
        } else if (reservationInfoForm.cellNo.length < 10) {
          isCellIsLess.value = true;
        }
        return;
      }
      if (
        reservationInfoForm.cellNo.length > 10 &&
        reservationInfoForm.cellNo[0] !== "0"
      ) {
        isInvalidCellNo.value = true;
        return;
      }
      isCustomerNameRequired.value = false;
      isCellNoRequired.value = false;
      isCellIsLess.value = false;
      isInvalidCellNo.value = false;

      store.commit("setReservationInfo", reservationInfoForm);
      router.push({ name: "ReservationConfirmation" });
    };

    const handleNumber = (evt: any) => {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode === 46) {
        evt.preventDefault();
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      } else {
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    };

    const handleNumberRegex = (event: any) => {
      const value = event.target.value;
      var numberPattern = /^[0-9]+$/g;
      if (numberPattern.test(value)) {
        reservationInfoForm.cellNo = value;
        localStorage.setItem("CellNo", JSON.stringify(value));
      } else {
        reservationInfoForm.cellNo = "";
      }
    };

    const setSymptomAndTrouble = (event: any) => {
      const value = event.target.value;
      localStorage.setItem("Troubles", JSON.stringify(value));
    };

    const convertToFullWidthName = () => {
      reservationInfoForm.customerName = convertToFullWidthKana(
        reservationInfoForm.customerName
      );
      localStorage.setItem(
        "Fullname",
        JSON.stringify(convertToFullWidthKana(reservationInfoForm.customerName))
      );
    };
    const handleTrim = () => {
      reservationInfoForm.customerName = reservationInfoForm.customerName.trim();
    };

    return {
      reservationInfoForm,
      customer,
      isCustomerNameRequired,
      isCellNoRequired,
      isCellIsLess,
      isInvalidCellNo,
      //
      goBack,
      handleNumber,
      handleNumberRegex,
      setSymptomAndTrouble,
      goNext,
      convertToFullWidthName,
      handleTrim
    };
  }
});
