import { InjectionKey } from "vue";
import { Store, createStore, useStore as baseUseStore } from "vuex";
import { State } from "@/vue";
import { AREA, CONSULTATION_CLASSIFICATION, SYMPTOMS } from "../enums";
import { ReservationInfo, TreatmentMenu } from "../interfaces";

// define injection key
export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: {
    token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
    lineProfile: null,
    customer: null,
    treatmentMenu: null,
    storeSelection: {
      consultationClassification: "",
      symptom: "",
      area: "",
      // consultationClassification: CONSULTATION_CLASSIFICATION.FIRST_VISIT,
      // symptom: SYMPTOMS.DAILY_PAIN,
      // area: AREA.KYOTO,
      store: null,
      storeId: "",
    },
    lineIDLinkage: {
      customerNumber: "",
      name: "",
      dob: { year: "", month: "", date: "" },
      phoneOrMobileNumber: "",
    },
    reservationInfo: {
      date: "",
      startTime: "",
      endTime: "",
      schedule: [],
      customerName: "",
      cellNo: "",
      symptomAndTouble: "",
    },
  },
  mutations: {
    setToken(state: State, token: string) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    setLineProfile(state: State, lineProfile: any) {
      state.lineProfile = lineProfile;
    },
    setCustomer(state: State, customer: any) {
      state.customer = customer;
    },
    setStoreSelection(state: State, storeSelection: any) {
      state.storeSelection = storeSelection;
    },
    setLineIDLinkage(state: State, lineIDLinkage: any) {
      state.lineIDLinkage = lineIDLinkage;
    },
    setReservationInfo(state: State, reservationInfo: ReservationInfo) {
      state.reservationInfo = reservationInfo;
    },
    setTreatmentMenu(state: State, treatmentMenu: TreatmentMenu) {
      state.treatmentMenu = treatmentMenu;
    },
  },
  actions: {
    setToken({ commit }, payload) {
      commit("setToken", payload);
    },
    setLineProfile({ commit }, payload) {
      commit("setLineProfile", payload);
    },
    setCustomer({ commit }, payload) {
      commit("setCustomer", payload);
    },
    setLineIDLinkage({ commit }, payload) {
      commit("setLineIDLinkage", payload);
    },
    setReservationInfo({ commit }, payload) {
      return commit("setReservationInfo", payload);
    },
    setStoreSelection({ commit }, payload) {
      commit("setStoreSelection", payload);
    },
    setTreatmentMenu({ commit }, payload) {
      commit("setTreatmentMenu", payload);
    },
  },
  modules: {},
  getters: {
    token: (state) => state.token,
    lineProfile: (state) => state.lineProfile,
    customer: (state) => state.customer,
    treatmentMenu: (state) => state.treatmentMenu,
    storeSelection: (state) => state.storeSelection,
    lineIDLinkage: (state) => state.lineIDLinkage,
    reservationInfo: (state) => state.reservationInfo,
  },
});

export function useStore() {
  return baseUseStore(key);
}
